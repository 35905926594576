@use 'sass:color';

@mixin light {
  --color-bg-preview-frame: var(--white);
  --color-bg-margin-size-indicator: var(--white);
  --color-bg-margin-preview-text: rgba(113, 116, 170, 32%);
  --color-bg-margin-preview-image: rgba(113, 116, 170, 32%);
  --color-bg-margins-tooltip-space-level: var(--white);
  --color-bg-margins-sidebar-tabs: #d8ddeb;
  --color-bg-margin-error: #d94343;
  --color-bg-range-thumb-space-level: #36385b;
  --color-bg-range-track-space-level: #e1e4eb;
  --color-bg-range-dots-space-level: #bfc2ce;
  --color-bg-input-margins: #fff;

  --color-font-margins-tooltip-space-level: #515269;
  --color-font-tab: #767997;
  --color-font-tab-active: #292b4b;
  --color-font-tab-ebook: var(--color-font-main);
  --color-font-nav-tab-text: #292b4b;
  --color-font-margin-error: #d94343;
  --color-font-multi-columns-disabled: #292b4b;

  --color-border-margin: #bfc0d2;
  --color-border-margins-group: rgba(34, 36, 67, 40%);
  --color-border-margin-input-highlighted: rgba(34, 36, 67, 40%);
  --color-border-nav-tabs: #d9dbec;
  --color-border-margins-separator: #d9dbec;

  --color-fill-back-button: #36385b;
  --color-fill-space-level-info: #292b4b;
  --color-fill-icon-back-button: #767997;
}

@mixin dark {
  --color-bg-preview-frame: var(--white);
  --color-bg-margin-size-indicator: var(--white);
  --color-bg-margin-preview-text: rgba(113, 116, 170, 32%);
  --color-bg-margin-preview-image: rgba(113, 116, 170, 32%);
  --color-bg-margins-tooltip-space-level: var(--white);
  --color-bg-margins-sidebar-tabs: #292b4b;
  --color-bg-margin-error: #d94343;
  --color-bg-range-thumb-space-level: var(--white);
  --color-bg-range-track-space-level: #53557c;
  --color-bg-range-dots-space-level: #36385b;
  --color-bg-input-margins: #222443;

  --color-font-margins-tooltip-space-level: #515269;
  --color-font-tab: #767997;
  --color-font-tab-active: var(--white);
  --color-font-tab-ebook: var(--color-font-main);
  --color-font-nav-tab-text: var(--white);
  --color-font-margin-error: #d94343;
  --color-font-multi-columns-disabled: #bfc0d2;

  --color-border-margin: #bfc0d2;
  --color-border-margins-group: rgba(255, 255, 255, 40%);
  --color-border-margin-input-highlighted: var(--white);
  --color-border-nav-tabs: #484a70;
  --color-border-margins-separator: #53557c;

  --color-fill-back-button: #36385b;
  --color-fill-space-level-info: var(--white);
  --color-fill-icon-back-button: #36385b;
}
