@mixin scrollbars($bg, $thumb) {
  --scrollbar-bg: #{$bg};
  --thumb-bg: #{$thumb};

  scrollbar-color: var(--thumb-bg) var(--scrollbar-bg);
  scrollbar-gutter: stable;
  scrollbar-width: 4px;

  &::-webkit-scrollbar {
    z-index: 10;
    width: 11px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-bg);
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid var(--scrollbar-bg);
    border-radius: 6px;
    background-color: var(--thumb-bg);
  }
}

@mixin overlayscrollbars {
  .os-scrollbar {
    // The size of the scrollbar
    --os-size: 6px;
    // The axis-perpendicular padding of the scrollbar (horizontal: padding-y, vertical: padding-x)
    --os-padding-perpendicular: 0;
    // The axis padding of the scrollbar (horizontal: padding-x, vertical: padding-y)
    --os-padding-axis: 6px;
    // The border radius of the scrollbar track
    --os-track-border-radius: 10px;
    // The background of the scrollbar track
    --os-track-bg: none;
    // The :hover background of the scrollbar track
    --os-track-bg-hover: none;
    // The :active background of the scrollbar track
    --os-track-bg-active: none;
    // The border of the scrollbar track
    --os-track-border: none;
    // The :hover background of the scrollbar track
    --os-track-border-hover: none;
    // The :active background of the scrollbar track
    --os-track-border-active: none;
    // The border radius of the scrollbar handle
    --os-handle-border-radius: 10px;
    // The background of the scrollbar handle
    --os-handle-bg: var(--surface-on-island);
    // The :hover background of the scrollbar handle
    --os-handle-bg-hover: none;
    // The :active background of the scrollbar handle
    --os-handle-bg-active: none;
    // The border of the scrollbar handle
    --os-handle-border: none;
    // The :hover border of the scrollbar handle
    --os-handle-border-hover: none;
    // The :active border of the scrollbar handle
    --os-handle-border-active: none;
    // The min size of the scrollbar handle
    --os-handle-min-size: 33px;
    // The max size of the scrollbar handle
    --os-handle-max-size: none;
    // The axis-perpendicular size of the scrollbar handle (horizontal: height, vertical: width)
    --os-handle-perpendicular-size: 100%;
    // The :hover axis-perpendicular size of the scrollbar handle (horizontal: height, vertical: width)
    --os-handle-perpendicular-size-hover: 100%;
    // The :active axis-perpendicular size of the scrollbar handle (horizontal: height, vertical: width)
    --os-handle-perpendicular-size-active: 100%;
    // Increases the interactive area of the scrollbar handle.
    --os-handle-interactive-area-offset: 0;
  }
}
