@import 'mixins';

@mixin light {
  --color-editor-area-bg: #f0f4f9;
  --color-editor-block-bg: #fff;
  --color-editor-block-style-text: #7f8093;
  --color-editor-text: #444562;

  // toolbars
  --color-markup-item-hover-bg: #e9edf5;

  // menu
  --color-markup-menu-bg: #fff;
  --color-markup-menu-border: #d9dbec;

  // paginator
  --color-markup-paginator-bg: #fff;
  --color-markup-paginator-selected-bg: #fff;
  --color-markup-paginator-hover-bg: var(--color-markup-item-hover-bg);
  --color-markup-paginator-text: #444562;
  --color-markup-paginator-text-selected: #444562;

  // footnotes
  --color-border-footnote-number: #d9dbec;
  --color-bg-hover-table-context-menu-item: #b9bbd3;
}

@mixin dark {
  @include light;

  --color-bg-editor-block: #222443;
  --color-bg-hover-table-context-menu-item: #222443;
}
