@use 'mixins.scss' as mixins;

html {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  @include mixins.text;
  @include mixins.scrollbars-main;

  height: 100vh;
  margin: 0;
  background-color: var(--color-bg-main);
}

nav,
footer,
header {
  display: block;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  margin: 0;
  margin-top: 0;
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

input,
button,
textarea {
  font-family: inherit;
}

button {
  background-color: transparent;
  background-image: none;
  cursor: pointer;
  outline: none;
  text-transform: none;
}

button:focus {
  outline: none;
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
