@use 'sass:color';

@mixin light {
  --color-font-input: #292b4b;
  --color-font-placeholder: #{color.change(#292b4b, $alpha: 0.6)};
  --color-font-input-prefix: #696a7f;
  --color-bg-input-prefix-border: #696a7f;
  --color-bg-input: #d8ddeb;
  --color-bg-input-hover: #c4cbde;
  --color-bg-input-2: #e1e6ef;
  --color-bg-input-on-popup: #e9edf5;
  --color-border-input: transparent;
  --color-border-input-focused: #d9dbec;
  --color-bg-switch-off: #767a84;
  --color-bg-switch-disabled: #d7dbe3;
  --color-bg-switch-thumb-disabled: #e9ebf1;
  --color-bg-color-palette-selector: #fff;

  // range
  --color-range-track: #fff;
  --color-range-thumb: var(--white);
  --color-range-thumb-hover: var(--accent);
  --color-range-thumb-border-color: rgb(236 239 241);
  --color-range-track-border-color: rgb(207 216 220);

  // icons
  --color-bg-input-icons: #292b4b80;
}

@mixin dark {
  --color-font-input: rgb(255 255 255 / 80%);
  --color-font-placeholder: #{color.change(#fff, $alpha: 0.6)};
  --color-font-input-prefix: #696a7f;
  --color-bg-input-prefix-border: rgba(255, 255, 255, 0.32);
  --color-bg-input: #1b1d3a;
  --color-bg-input-hover: #484a70;
  --color-bg-input-2: #222443;
  --color-bg-input-on-popup: #222443;
  --color-border-input: transparent;
  --color-border-input-focused: rgba(255 255 255 / 20%);
  --color-bg-switch-off: #767a84;
  --color-bg-switch-disabled: #55586f;
  --color-bg-switch-thumb-disabled: #7e8092;
  --color-bg-color-palette-selector: #222443;

  // range
  --color-range-track: #fff;
  --color-range-thumb: var(--white);
  --color-range-thumb-hover: var(--accent);
  --color-range-thumb-border-color: rgb(236 239 241);
  --color-range-track-border-color: rgb(207 216 220);

  // icons
  --color-bg-input-icons: #ffffff80;
}
