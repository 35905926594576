// @import 'variables.scss';
@use 'theming/base.scss' as theme;

@mixin text {
  color: var(--color-font-main);
  font-family: Roboto, sans-serif;
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 1.2;
}

@mixin text-mono {
  @include text;

  font-family: 'Roboto Mono', monospace;
}

@mixin text-heading {
  @include text;

  font-size: 24px;
  font-weight: 600;
  line-height: 1.35;
}

@mixin error-text {
  background-color: var(--accent);
  color: var(--color-font-on-accent);
  font-size: var(--font-size);
  letter-spacing: 0.02em;
  line-height: 1.2;
  text-align: center;
}

@mixin button {
  @include text;

  padding: 8px 12px;
  border-radius: 86px;
  cursor: pointer;
  font-size: 24px;
  letter-spacing: 0.02em;
  line-height: 1.2;
  text-align: center;

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin main-action-button {
  @include button;

  background: var(--accent);
  color: var(--color-font-on-accent);
}

@mixin button-accent-on-hover {
  @include button;

  color: var(--color-font-main);

  transition: background 0.5s;

  &:hover {
    background: var(--accent);
    color: var(--color-font-on-accent);
  }
}

@mixin scrollbars($bg, $thumb) {
  --scrollbar-bg: #{$bg};
  --thumb-bg: #{$thumb};

    /* For Safari, Chrome, and offshoots */
  /* &::-webkit-scrollbar { */
  /*   width: 8px; */
  /*   height: 8px; */
  /* } */
  /* &::-webkit-scrollbar-thumb { */
  /*   background: gray; */
  /*   border-radius: 8px; */
  /* } */
  /* &::-webkit-scrollbar-track { */
  /*   background: transparent; */
  /* } */

  /* For Firefox and offshoots */
  scrollbar-color: gray transparent;
  scrollbar-width: thin;

  /* @supports not selector(::-webkit-scrollbar) { */
  /*   html { */
  /*     scrollbar-width: thin; */
  /*     scrollbar-color: var(--thumb-color) var(--track-color); */
  /*   } */
  /* } */
  /* scrollbar-color: var(--thumb-bg) var(--scrollbar-bg); */
  /* scrollbar-gutter: stable; */
  /* scrollbar-width: 4px; */
  /**/
  /* &::-webkit-scrollbar { */
  /*   z-index: 10; */
  /*   width: 11px; */
  /* } */
  /**/
  /* &::-webkit-scrollbar-track { */
  /*   background: var(--scrollbar-bg); */
  /* } */
  /**/
  /* &::-webkit-scrollbar-thumb { */
  /*   border: 3px solid var(--scrollbar-bg); */
  /*   border-radius: 6px; */
  /*   background-color: var(--thumb-bg); */
  /* } */
}

@mixin scrollbars-main {
  @include scrollbars($bg: var(--color-bg-main), $thumb: var(--color-bg-main-scroll-thumb));
}

@mixin scrollbars-light {
  @include scrollbars($bg: var(--color-bg-main), $thumb: var(--color-bg-main-scroll-thumb));
}

@mixin scrollbars-panels {
  @include scrollbars($bg: var(--color-bg-panel), $thumb: var(--color-bg-panel-scroll-thumb));
}

@mixin panel {
  background-color: var(--color-bg-panel);
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
}

@mixin panel-rounded {
  @include panel;

  border-radius: 5px;
  background-color: var(--color-bg-panel);
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);
}

@mixin elevated {
  // box-shadow: 0 0 15px rgb(0 0 0 / 20%);
  border: 1px solid var(--color-border-elevated);
  border-radius: var(--border-radius-l);
  background-color: var(--color-bg-elevated);
}

@mixin elevated-panel {
  border-radius: 15px;
  background-color: var(--color-bg-popup);
  box-shadow: 0 0 15px rgb(0 0 0 / 20%);

  color: var(--color-font-on-popup);
}

// TODO remove this mixin
@mixin popup {
  @include elevated-panel;
}

@mixin screen-min($min) {
  @media (min-width: $min) {
    @content;
  }
}

@mixin screen-max($max) {
  @media (max-width: ($max - 1)) {
    @content;
  }
}

@mixin screen-minmax($min, $max) {
  @media (min-width: $min) and (max-width: ($max - 1)) {
    @content;
  }
}

@mixin svg-color($color) {
  svg {
    & > *[fill] {
      // override only elements with "fill" property
      fill: $color;

      &.accent {
        fill: var(--accent);
      }
    }

    & > * > *[fill] {
      // override only elements with "fill" property
      fill: $color;
    }

    & > *[stroke] {
      // override only elements with "stroke" property
      stroke: $color;
    }

    & > * > *[stroke] {
      // override only elements with "stroke" property
      stroke: $color;
    }
  }
}

// dont forget to add 80px padding to the sidebar content
@mixin sidebar-bottom-buttons-tray {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 100px;
  align-items: flex-end;
  justify-content: center;
  padding: 16px;
  background: linear-gradient(0deg, var(--color-bg-sidebar) 60%, transparent);
  gap: 8px;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }
}

@mixin drop-cap-styles {
  display: inline-block;
  margin: 2px 1px 0 0;
  color: var(--drop-cap-color);
  float: left;
  font-size: var(--drop-cap-font-size);
  line-height: var(--drop-cap-line-height);
  text-transform: capitalize;
  vertical-align: top;
}
