@mixin light {
  --color-border-pricing: var(--color-bg-panel-border);
  --color-border-pricing-block-separator: var(--color-bg-panel-border);
  --color-border-pricing-credits-calculator-block-separator: var(--color-bg-panel-border);
  --color-font-pricing: var(--color-font-main);
  --color-font-pricing-small-text: #767997;
  --color-font-pricing-credits-calculator-profit: var(--white);
  --color-font-pricing-calculator-panel-gold-credit: #fbae37;
  --color-font-pricing-calculator-panel-show-calculation: rgb(0 0 0 / 50%);
  --color-fill-pricing-icons: var(--color-font-pricing);
  --color-bg-pricing-credits-calculator-main: var(--color-bg-main);
  --color-bg-pricing-credits-calculator-result: var(--white);
  --color-bg-pricing-credits-calculator-profit: var(--color-success-border);
  --color-bg-pricing-promocode-backdrop: rgba(255, 255, 255, 0.64);
  --color-bg-pricing-promocode-close: var(--color-font-main);

  ///// FOR DELETE
  // credits-calculator
  --color-bg-credits-calculator-result: #fff;
  --color-bg-credits-calculator-profit: #0cb627;
  --color-border-credits-calculator: #d9dbec;
  --color-border-credits-calculator-average-column: #caccde;
  --color-border-credits-calculator-calculator: #caccde;
  --color-bs-credits-calculator: rgb(0 0 0 / 20%);
  --color-font-credits-calculator: var(--color-font-pricing);
  --color-font-credits-calculator-icon-right: #484a70;
  --color-font-credits-calculator-profit: #fff;
  --color-font-credits-calculator-button-calculate: #fff;
  --color-font-credits-calculator-input: var(--color-font-pricing);
  --color-bg-credits-calculator-input: #fff;
  --color-bg-credits-calculator-calculator: #f0f0f0;
  --color-fill-credits-calculator-icons: var(--color-font-pricing);
  --color-stroke-credits-calculator-icons: var(--color-font-pricing);

  // tariff-news
  --color-border-tariff-news: #d9dbec;
  --color-font-tariff-news: var(--color-font-pricing);
  --color-font-tariff-news-important: #e02379;
  --color-bg-tariff-news: #fff;

  // tariff-news-modal
  --color-border-tariff-news-modal: #d9dbec;
  --color-border-tariff-news-modal-divider: #d9dbec;
  --color-bg-tariff-news-modal: #fff;
  --color-bg-tariff-news-modal-backdrop: #f0f4f9;
  --color-bg-tariff-news-modal-close: #fff;
  --color-bs-tariff-news-modal: rgb(41 43 75 / 15%);
  --color-font-tariff-news-modal: var(--color-font-pricing);
  --color-fill-tariff-news-modal: var(--color-font-pricing);
  --color-stroke-tariff-news-modal: var(--color-font-pricing);
  --color-fill-tariff-news-modal-close: #e02379;
  --color-stroke-tariff-news-modal-close: #e02379;

  // tariff-message
  --color-border-tariff-message: #d9dbec;
  --color-font-tariff-message: #fff;
  --color-font-tariff-message-offer-date: #fff;
  --color-bg-tariff-message: #e02379;
  --color-fill-tariff-message-icons: var(--color-font-pricing);
  --color-stroke-tariff-message-icons: var(--color-font-pricing);

  // tarriff-table
  --color-border-tariff-table: #d9dbec;
}

@mixin dark {
  --color-border-pricing: var(--color-bg-panel-border);
  --color-border-pricing-block-separator: var(--color-bg-panel-border);
  --color-border-pricing-credits-calculator-block-separator: var(--color-bg-panel-border);
  --color-font-pricing: var(--color-font-main);
  --color-font-pricing-small-text: #767997;
  --color-font-pricing-credits-calculator-profit: var(--white);
  --color-font-pricing-calculator-panel-gold-credit: #fbae37;
  --color-font-pricing-calculator-panel-show-calculation: rgb(255 255 255 / 50%);
  --color-fill-pricing-icons: var(--color-font-pricing);
  --color-bg-pricing-credits-calculator-main: var(--color-bg-main);
  --color-bg-pricing-credits-calculator-result: var(--color-bg-panel);
  --color-bg-pricing-credits-calculator-profit: var(--color-success-border);
  --color-bg-pricing-promocode-backdrop: rgba(48, 49, 81, 0.64);
  --color-bg-pricing-promocode-close: var(--color-font-main);

  ///// FOR DELETE
  --color-bg-credits-calculator-result: #35375a;
  --color-bg-credits-calculator-profit: #0cb627;
  --color-border-credits-calculator: #484a70;
  --color-border-credits-calculator-average-column: #484a70;
  --color-border-credits-calculator-calculator: #484a70;
  --color-bs-credits-calculator: rgb(0 0 0 / 20%);
  --color-font-credits-calculator: #fff;
  --color-font-credits-calculator-icon-right: #fff;
  --color-font-credits-calculator-profit: #fff;
  --color-font-credits-calculator-button-calculate: #fff;
  --color-font-credits-calculator-input: #fff;
  --color-bg-credits-calculator-input: #35375a;
  --color-bg-credits-calculator-calculator: #292b4b;
  --color-fill-credits-calculator-icons: #fff;
  --color-stroke-credits-calculator-icons: #fff;

  // tariff-news
  --color-border-tariff-news: #484a70;
  --color-font-tariff-news: #fff;
  --color-font-tariff-news-important: #e02379;
  --color-bg-tariff-news: #35375a;

  // tariff-news-modal
  --color-border-tariff-news-modal: #d9dbec;
  --color-border-tariff-news-modal-divider: #d9dbec;
  --color-bg-tariff-news-modal: #fff;
  --color-bg-tariff-news-modal-backdrop: #f0f4f9;
  --color-bg-tariff-news-modal-close: #fff;
  --color-bs-tariff-news-modal: rgb(41 43 75 / 15%);
  --color-font-tariff-news-modal: #292b4b;
  --color-fill-tariff-news-modal: #292b4b;
  --color-stroke-tariff-news-modal: #292b4b;
  --color-fill-tariff-news-modal-close: #e02379;
  --color-stroke-tariff-news-modal-close: #e02379;

  // tariff-message
  --color-border-tariff-message: #484a70;
  --color-font-tariff-message: #fff;
  --color-font-tariff-message-offer-date: #292b4b;
  --color-bg-tariff-message: #e02379;
  --color-fill-tariff-message-icons: #fff;
  --color-stroke-tariff-message-icons: #fff;

  // tarriff-table
  --color-border-tariff-table: #484a70;
}
