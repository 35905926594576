@use 'sass:color';

@mixin common {
  --white: #fff;
  --black: #000;
  --color-success-border: #0cb627;
  --color-success-text: #0cb627;
  --color-success: rgba(31 188 56 / 80%);
  --color-error-border: #d94343;
  --color-error-text: #d94343;
  --color-error: rgba(216 0 0 / 65%);
  --color-error-info: #e75656;
  --color-faq-text: #9697a9;
  --color-success-notice: #81e95d;
  --color-warning-notice: #ef8727;
  --color-error-notice: #e75656;
  --color-markup-font-main: #292b4b;
}

@mixin light {
  --accent: #e02379;
  --color-font-on-accent: #fff;
  --color-accent-gradiend: #ad1b5e;

  // Fonts
  --color-font-main: #292b4b;
  --color-font-secondary: #696b81;
  --color-font-not-active: #{color.change(#292b4b, $alpha: 0.5)};
  --color-font-on-popup: #f6f6f6;
  --color-font-additional-info: #8788a4;
  --color-font-range-label: rgb(154 155 173 / 100%);
  --color-font-text: #696b81;
  --color-font-text-danger: #e05c5c;
  --color-border-elevated: #d9dbec;
  --color-border-language-selector: #e9edf5;
  --color-border-payments-card-divider: rgb(217 219 236 / 50%);

  // BG
  --color-bg-main: #f0f4f9;
  --color-bg-elevated: #fff;
  --color-bg-elevated-hover: #f2f2f2;
  --color-bg-panel: #fff;
  --color-bg-panel-border: #d9dbec;
  --color-bg-panel-shadow: rgba(41 43 75 / 15%);
  --color-bg-panel-disabled: #00000020;
  --color-bg-panel-2: rgb(0 0 0 / 16%);
  --color-bg-popup: #2b2d51;
  --color-bg-popup-overlay: #{color.change(#f6f6f6, $alpha: 0.8)};
  --color-bg-popup-overlay-new: rgb(173 182 190 / 80%);
  --color-bg-button: #fff;
  --color-bg-range: rgb(83 85 124 / 100%);
  --color-bg-range-dot: rgb(54 56 91 / 100%);
  --color-bg-tooltip: #fff;
  --color-bg-radio: #bfcae0;
  --color-bg-sidebar: #e9edf5;
  --color-bg-sidebar-inactive-tab: #d8ddeb;
  --color-bg-divider: #e7e8f3;
  --color-bg-divider2: #484a73;
  --color-bg-panel-hover: #d8ddeb;
  --color-bg-app-bar-menu: #fff;
  --color-bg-app-bar-divider: #e7e8f3;
  --color-bg-app-bar-menu-token-action: #585a7a;
  --color-bg-blur-buttons-1: rgb(41 43 75 / 0%);
  --color-bg-blur-buttons-2: #f0f4f9;
  --color-bg-payments-period-selector: #e1e6ef;
  --color-bg-payments-card: #fff;
  --color-bg-payments-card-header: #fff;
  --color-bg-payments-tariff-active-progress-bar: #e1e6ef;

  // Notifications
  --color-info-border: #d9dbec;
  --color-info: rgba(255 255 255 / 55%);

  // scroll
  --color-bg-main-scroll-thumb: var(--color-font-not-active);
  --color-bg-panel-scroll-thumb: var(--color-font-not-active);

  // additional
  --color-button-toolbar: #696b81;

  // Payments
  --bg-gold: conic-gradient(
    from 42.4deg at 50% 50%,
    #e78327 -20.43deg,
    #ffb73a 56.59deg,
    #ef8727 126.33deg,
    #ffb73a 288.6deg,
    #e78327 339.57deg,
    #ffb73a 416.59deg
  );
  --color-font-on-gold: #905516;
  --box-shadow-panel: 0px 4px 10px 0px rgb(41 43 75 / 8%);
}

@mixin dark {
  --accent: rgb(224 35 121);
  --color-font-on-accent: #fff;
  --color-accent-gradiend: #ad1b5e;

  // Fonts
  --color-font-main: #fff;
  --color-font-secondary: #a0a2be;
  --color-font-not-active: #{color.change(#fff, $alpha: 0.5)};
  --color-font-on-popup: #1b2031;
  --color-font-additional-info: #8788a4;
  --color-font-range-label: rgb(154 155 173 / 100%);
  --color-font-text: #bec0d4;
  --color-font-text-danger: #e05c5c;
  --color-border-elevated: #555783;
  --color-border-language-selector: #35375b;
  --color-border-payments-card-divider: rgb(41 43 75 / 50%);

  // BG
  --color-bg-main: #292b4b;
  --color-bg-elevated: #3e416c;
  --color-bg-elevated-hover: #5b5d82;
  --color-bg-panel: #292b4b;
  --color-bg-panel-border: #484a70;
  --color-bg-panel-shadow: rgba(0 0 0 / 20%);
  --color-bg-panel-disabled: #3e4065;
  --color-bg-panel-2: rgb(0 0 0 / 16%);
  --color-bg-popup: #fff;
  --color-bg-popup-overlay: #{color.change(#292b4b, $alpha: 0.8)};
  --color-bg-popup-overlay-new: rgb(22 23 40 / 80%);
  --color-bg-button: #6c6e96;
  --color-bg-range: rgb(83 85 124 / 100%);
  --color-bg-range-dot: rgb(54 56 91 / 100%);
  --color-bg-tooltip: #35375a;

  // --color-bg-tooltip: #292b4b;
  --color-bg-radio: #1b1d3a;
  --color-bg-sidebar: #292b4b;
  --color-bg-sidebar-inactive-tab: #292b4b;
  --color-bg-divider: #3c3e63;
  --color-bg-divider2: #484a73;
  --color-bg-panel-hover: #484a70;
  --color-bg-app-bar-menu: #484a73;
  --color-bg-app-bar-divider: #53557c;
  --color-bg-app-bar-menu-token-action: #585a7a;
  --color-bg-blur-buttons-1: rgb(240 244 249 / 0%);
  --color-bg-blur-buttons-2: #292b4b;
  --color-bg-payments-period-selector: #1b1d3a;
  --color-bg-payments-card: #484a73;
  --color-bg-payments-card-header: #35375a;
  --color-bg-payments-tariff-active-progress-bar: rgb(255 255 255 / 32%);

  // Notifications
  --color-info-border: #484a70;
  --color-info: rgba(53 55 90 / 55%);

  // scroll
  --color-bg-main-scroll-thumb: var(--color-font-not-active);
  --color-bg-panel-scroll-thumb: var(--color-font-not-active);

  // additional
  --color-button-toolbar: #5f6187;

  // Payments
  --bg-gold: conic-gradient(
    from 42.4deg at 50% 50%,
    #e78327 -20.43deg,
    #ffb73a 56.59deg,
    #ef8727 126.33deg,
    #ffb73a 288.6deg,
    #e78327 339.57deg,
    #ffb73a 416.59deg
  );
  --color-font-on-gold: #905516;
  --box-shadow-panel: none;
}
