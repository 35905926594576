@use 'sass:color';

@mixin light {
  --color-bg-margins-sidebar-tabs: #d8ddeb;
  --color-bg-radio-button-content: rgba(41, 43, 75, 20%);
  --color-bg-active-radio-button-content: #292b4b;
  --color-bg-disable-radio-button: #bfcae0;
  --color-bg-disable-radio-button-content: #a7b2c4;
  --color-bg-running-titles-style-preview: var(--white);

  --color-font-running-title-info: #e78a56;
  --color-font-tab-active: #292b4b;

  --color-border-running-titles-separator: #d9dbec;
  --color-border-disable-radio-button: #f0f1f4;
  --color-border-running-titles-style-preview: #d9dbec;

  --color-fill-running-titles-icon-settings-enable: var(--color-fill-space-level-info);
  --color-fill-running-titles-icon-settings-disable: #d9dbec;
}

@mixin dark {
  --color-bg-margins-sidebar-tabs: #292b4b;
  --color-bg-radio-button-content: rgba(255, 255, 255, 20%);
  --color-bg-active-radio-button-content: var(--white);
  --color-bg-disable-radio-button: #313354;
  --color-bg-disable-radio-button-content: #2b2d49;
  --color-bg-running-titles-style-preview: var(--white);

  --color-font-running-title-info: #e78a56;
  --color-font-tab-active: var(--white);

  --color-border-running-titles-separator: #53557c;
  --color-border-disable-radio-button: #3a3c5f;
  --color-border-running-titles-style-preview: var(--color-bg-running-titles-style-preview);

  --color-fill-running-titles-icon-settings-enable: var(--color-fill-space-level-info);
  --color-fill-running-titles-icon-settings-disable: #53557c;
}
