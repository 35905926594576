@mixin font-face(
  $font-name,
  $font-path,
  $format,
  // path + file name. Ex : path/to/font/filename
  $font-weight: 400,
  $font-style: normal
) {
  @font-face {
    font-family: $font-name;
    font-style: $font-style;
    font-weight: $font-weight;

    // src: url('#{$font-path}.woff2') format('woff2'), url('#{$font-path}.woff') format('woff'),
    //   url('#{$font-path}.ttf') format('truetype');
    src: url($font-path) format($format);
  }
}

@include font-face('Comic Cat', '/assets/fonts/Comic Cat/Comic_CAT.woff2', 'woff2', normal, normal);
@include font-face('PiazzollaSC', '/assets/fonts/PiazzollaSC/PiazzollaSC-Bold.woff2', 'woff2', bold, normal);
@include font-face('PiazzollaSC', '/assets/fonts/PiazzollaSC/PiazzollaSC-Italic.woff2', 'woff2', normal, italic);
@include font-face('PiazzollaSC', '/assets/fonts/PiazzollaSC/PiazzollaSC-BoldItalic.woff2', 'woff2', bold, italic);
@include font-face('PiazzollaSC', '/assets/fonts/PiazzollaSC/PiazzollaSC-Regular.woff2', 'woff2', normal, normal);
@include font-face(
  'Source Serif',
  '/assets/fonts/Source Serif/SourceSerif4Subhead-Regular.woff2',
  'woff2',
  normal,
  normal
);
@include font-face('Source Serif', '/assets/fonts/Source Serif/SourceSerif4Subhead-It.woff2', 'woff2', normal, italic);
@include font-face(
  'Source Serif',
  '/assets/fonts/Source Serif/SourceSerif4Subhead-BoldIt.woff2',
  'woff2',
  bold,
  italic
);
@include font-face('Source Serif', '/assets/fonts/Source Serif/SourceSerif4Subhead-Bold.woff2', 'woff2', bold, normal);
@include font-face('Murmure', '/assets/fonts/Murmure/fonts/le-murmure.woff2', 'woff2', normal, normal);
@include font-face('Dance Partner', '/assets/fonts/Dance Partner/dance_partner.woff2', 'woff2', normal, normal);
@include font-face('Montserrat', '/assets/fonts/Montserrat/Montserrat-Regular.woff2', 'woff2', normal, normal);
@include font-face('Montserrat', '/assets/fonts/Montserrat/Montserrat-BoldItalic.woff2', 'woff2', bold, italic);
@include font-face('Montserrat', '/assets/fonts/Montserrat/Montserrat-MediumItalic.woff2', 'woff2', normal, italic);
@include font-face('Montserrat', '/assets/fonts/Montserrat/Montserrat-Italic.woff2', 'woff2', normal, italic);
@include font-face('Montserrat', '/assets/fonts/Montserrat/Montserrat-Bold.woff2', 'woff2', bold, normal);
@include font-face('Garcia Marquez', '/assets/fonts/Garcia Marquez/GarciaMarquez.woff2', 'woff2', normal, normal);
@include font-face('Lack', '/assets/fonts/Lack/Lack-Regular.woff2', 'woff2', normal, normal);
@include font-face('Lack', '/assets/fonts/Lack/Lack-Italic.woff2', 'woff2', normal, italic);
@include font-face('Finlandica', '/assets/fonts/Finlandica/Finlandica-Italic.woff2', 'woff2', normal, italic);
@include font-face('Finlandica', '/assets/fonts/Finlandica/Finlandica-Regular.woff2', 'woff2', normal, normal);
@include font-face('Finlandica', '/assets/fonts/Finlandica/Finlandica-Bold.woff2', 'woff2', bold, normal);
@include font-face('Finlandica', '/assets/fonts/Finlandica/Finlandica-BoldItalic.woff2', 'woff2', bold, italic);
@include font-face('PT Serif', '/assets/fonts/PT Serif/PT-BoldItalic.woff2', 'woff2', bold, italic);
@include font-face('PT Serif', '/assets/fonts/PT Serif/PT-Italic.woff2', 'woff2', normal, italic);
@include font-face('PT Serif', '/assets/fonts/PT Serif/PT-Regular.woff2', 'woff2', normal, normal);
@include font-face('PT Serif', '/assets/fonts/PT Serif/PT-Bold.woff2', 'woff2', bold, normal);
@include font-face('Lack Line', '/assets/fonts/Lack Line/Lack-Line-Regular.woff2', 'woff2', normal, normal);
@include font-face('Lack Line', '/assets/fonts/Lack Line/Lack-Line-Italic.woff2', 'woff2', normal, italic);
@include font-face(
  'Random Grotesque',
  '/assets/fonts/Random Grotesque/RG-StandardBoldItalic.woff2',
  'woff2',
  bold,
  italic
);
@include font-face('Random Grotesque', '/assets/fonts/Random Grotesque/RG-StandardBold.woff2', 'woff2', bold, normal);
@include font-face('Random Grotesque', '/assets/fonts/Random Grotesque/RG-StandardBook.woff2', 'woff2', normal, normal);
@include font-face(
  'Random Grotesque',
  '/assets/fonts/Random Grotesque/RG-StandardBookItalic.woff2',
  'woff2',
  normal,
  italic
);
@include font-face('Frankinity', '/assets/fonts/Frankinity/FRANKINITY.woff2', 'woff2', normal, normal);
@include font-face('Vollkorn', '/assets/fonts/Vollkorn/Vollkorn-Regular.woff2', 'woff2', normal, normal);
@include font-face('Vollkorn', '/assets/fonts/Vollkorn/Vollkorn-Italic.woff2', 'woff2', normal, italic);
@include font-face('Vollkorn', '/assets/fonts/Vollkorn/Vollkorn-BoldItalic.woff2', 'woff2', bold, italic);
@include font-face('Vollkorn', '/assets/fonts/Vollkorn/Vollkorn-Bold.woff2', 'woff2', bold, normal);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-Bold.woff2', 'woff2', bold, normal);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-Italic.woff2', 'woff2', normal, italic);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-BoldItalic.woff2', 'woff2', bold, italic);
@include font-face('Roboto', '/assets/fonts/Roboto/Roboto-Regular.woff2', 'woff2', normal, normal);
@include font-face(
  'Arturito Rostype',
  '/assets/fonts/Arturito Rostype/Arturito Slab_v2.woff2',
  'woff2',
  normal,
  normal
);
@include font-face('Onest', '/assets/fonts/Onest/TTF/Onest-Bold.woff2', 'woff2', bold, normal);
@include font-face('Onest', '/assets/fonts/Onest/TTF/Onest-ExtraBold.woff2', 'woff2', bold, normal);
@include font-face('Onest', '/assets/fonts/Onest/TTF/Onest-Black.woff2', 'woff2', normal, normal);
