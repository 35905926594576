@use 'mixins' as mixins;

h1 {
  @include mixins.text;

  padding: 0;
  margin: 0;

  font-size: var(--font-size-xxxl);
  font-weight: var(--font-weight-em);
  line-height: 1.3;
}

h2 {
  @include mixins.text;

  padding: 0;
  margin: 0;

  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-em);
  line-height: 1.3;
}

h3 {
  @include mixins.text;

  padding: 0;
  margin: 0;

  font-size: var(--font-size-m);
  font-weight: var(--font-weight-em);
  line-height: 1.3;
}

p {
  @include mixins.text;

  margin: 0;

  &.small {
    color: var(--color-font-additional-info);
    font-size: var(--font-size-s);
  }
}
