@use 'sass:color';

@mixin light {
  --color-bg-sidebar-preview: #e9edf5;
  --color-bg-hover-spreads-preview: #fff;
  --color-bg-payments-modal-separator: #f0f4f9;
  --color-bg-dots-preview: #bfc2ce;
  --color-bg-popup-overlay-preview: rgba(173, 182, 190, 0.8);
  --color-bg-backdrop-preview: rgba(240, 244, 249, 0.8);

  --color-border-spreads-preview: #d9dbec;
  --color-border-spreads-preview-center: #bfc0d2;

  --color-border-spreads-preview-separator: #d9dbec;

  --color-font-payments-modal-header: var(--color-font-main);
  --color-font-payments-modal-wallet: var(--color-font-main);
  --color-font-payments-modal-info-header: var(--color-font-main);
  --color-font-payments-modal-info-text: #696b81;

  --bg-payments-modal-gc-card: #f0f4f9;
  --bg-payments-modal-image: url('/assets/img/stars-bg-lt.svg');
}

@mixin dark {
  --color-bg-sidebar-preview: #36385b;
  --color-bg-hover-spreads-preview: #51547d;
  --color-bg-payments-modal-separator: #252638;
  --color-bg-dots-preview: #51547d;
  --color-bg-popup-overlay-preview: rgba(48, 49, 81, 0.9);
  --color-bg-backdrop-preview: rgba(48, 49, 81, 0.9);

  --color-border-spreads-preview: #fff;
  --color-border-spreads-preview-center: #bfc0d2;

  --color-border-spreads-preview-separator: #53557c;

  --color-font-payments-modal-header: var(--white);
  --color-font-payments-modal-info-header: var(--white);
  --color-font-payments-modal-info-text: #bec0d4;

  --bg-payments-modal-gc-card: linear-gradient(90deg, rgb(254 181 58 / 0%) 52.01%, rgb(254 181 58 / 10%) 100%), #22232c;
  --bg-payments-modal-image: url('/assets/img/stars-bg-dt.svg');
}
