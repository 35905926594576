@mixin light {
  --text-8: rgb(41 43 75 / 8%);
  --text-64: rgb(41 43 75 / 64%);
  --warning: rgb(231 138 86 / 100%);
}

@mixin dark {
  --text-8: rgb(255 255 255 / 8%);
  --text-64: rgb(255 255 255 / 64%);
  --warning: rgb(231 138 86 / 100%);
}
