@use 'sass:color';

@mixin light {
  // primary
  // primary basic
  --color-font-button-primary: #fff;
  --color-font-button-primary-hover: #f5d2e2;
  --color-font-button-primary-pressed: #e7aec8;
  --color-font-button-primary-focus: #fff;
  --color-font-button-primary-disabled: #ce7da2;

  --color-bg-button-primary: #e02379;
  --color-bg-button-primary-hover: #ce206f;
  --color-bg-button-primary-pressed: #bc1d66;
  --color-bg-button-primary-focus: #e02379;
  --color-bg-button-primary-disabled: #f2c0d7;

  // primary danger
  --color-font-button-primary-danger: #e05c5c;
  --color-font-button-primary-danger-hover: #ef9595;
  --color-font-button-primary-danger-pressed: #ffc4c4;
  --color-font-button-primary-danger-focus: #e05c5c;
  --color-font-button-primary-danger-disabled: #e7d5d5;

  // primary gold
  --color-font-button-primary-gold: #905516;
  --color-font-button-primary-gold-hover: #7d4a13;
  --color-font-button-primary-gold-pressed: #6b3f10;
  --color-font-button-primary-gold-focus: #905516;
  --color-font-button-primary-gold-disabled: #c0976b;

  --color-bg-button-primary-gold: conic-gradient(
    from 42deg at 50% 50%,
    #ffb73a 28.125deg,
    #ef8727 88.12500178813934deg,
    #ffb73a 221.24999284744263deg,
    #e78327 290.6250071525574deg,
    #ffb73a 360deg
  );
  --color-bg-button-primary-gold-hover: conic-gradient(
    from 42deg at 50% 50%,
    #edaa36 28.125deg,
    #de7d24 88.12500178813934deg,
    #edaa36 221.24999284744263deg,
    #d47824 290.6250071525574deg,
    #edaa36 360deg
  );
  --color-bg-button-primary-gold-pressed: conic-gradient(
    from 42deg at 50% 50%,
    #db9d32 28.125deg,
    #cc7321 88.12500178813934deg,
    #db9d32 221.24999284744263deg,
    #c26e21 290.6250071525574deg,
    #db9d32 360deg
  );
  --color-bg-button-primary-gold-focus: conic-gradient(
    from 42deg at 50% 50%,
    #ffb73a 28.125deg,
    #ef8727 88.12500178813934deg,
    #ffb73a 221.24999284744263deg,
    #e78327 290.6250071525574deg,
    #ffb73a 360deg
  );
  --color-bg-button-primary-gold-disabled: conic-gradient(
    from 42deg at 50% 50%,
    #ffd58c 28.125deg,
    #efb783 88.12500178813934deg,
    #ffd58c 221.24999284744263deg,
    #e7b17f 290.6250071525574deg,
    #ffd58c 360deg
  );

  // secondary
  // secondary basic
  --color-font-button-secondary: #292b4b;
  --color-font-button-secondary-hover: #5e5f76;
  --color-font-button-secondary-pressed: #62626e;
  --color-font-button-secondary-focus: #292b4b;
  --color-font-button-secondary-disabled: #cecece;

  --color-bg-button-secondary: #f0f4f9;
  --color-bg-button-secondary-hover: #dbdbdb;
  --color-bg-button-secondary-pressed: #c2c2c2;
  --color-bg-button-secondary-focus: #fff;
  --color-bg-button-secondary-disabled: #f2f2f2;

  // secondary danger
  --color-font-button-secondary-danger: #e05c5c;
  --color-font-button-secondary-danger-hover: #ef9595;
  --color-font-button-secondary-danger-pressed: #ffc4c4;
  --color-font-button-secondary-danger-focus: #e05c5c;
  --color-font-button-secondary-danger-disabled: #e7d5d5;

  // tertiary
  // tertiary basic
  --color-font-button-tertiary: #292b4b;
  --color-font-button-tertiary-hover: #7f8093;
  --color-font-button-tertiary-pressed: #bbbbc5;
  --color-font-button-tertiary-focus: #292b4b;
  --color-font-button-tertiary-disabled: #dcdce0;

  // tertiary danger
  --color-font-button-tertiary-danger: #e05c5c;
  --color-font-button-tertiary-danger-hover: #ef9595;
  --color-font-button-tertiary-danger-pressed: #ffc4c4;
  --color-font-button-tertiary-danger-focus: #e05c5c;
  --color-font-button-tertiary-danger-disabled: #e7d5d5;
}

@mixin dark {
  // primary
  // primary basic
  --color-font-button-primary: #fff;
  --color-font-button-primary-hover: #f2d2e0;
  --color-font-button-primary-pressed: #daacc1;
  --color-font-button-primary-focus: #fff;
  --color-font-button-primary-disabled: #c899af;

  --color-bg-button-primary: #e02379;
  --color-bg-button-primary-hover: #bc1d66;
  --color-bg-button-primary-pressed: #981852;
  --color-bg-button-primary-focus: #e02379;
  --color-bg-button-primary-disabled: #a35579;

  // primary danger
  --color-font-button-primary-danger: #e05c5c;
  --color-font-button-primary-danger-hover: #9d4047;
  --color-font-button-primary-danger-pressed: #7c313e;
  --color-font-button-primary-danger-focus: #e05c5c;
  --color-font-button-primary-danger-disabled: #5c3e3e;

  // secondary
  // secondary basic
  --color-font-button-secondary: #fff;
  --color-font-button-secondary-hover: #dedee5;
  --color-font-button-secondary-pressed: #bdbec8;
  --color-font-button-secondary-focus: #fff;
  --color-font-button-secondary-disabled: #9b9ba8;

  --color-bg-button-secondary: #6c6e96;
  --color-bg-button-secondary-hover: #5b5c7e;
  --color-bg-button-secondary-pressed: #494b66;
  --color-bg-button-secondary-focus: #6c6e96;
  --color-bg-button-secondary-disabled: #67687a;

  // secondary danger
  --color-font-button-secondary-danger: #e05c5c;
  --color-font-button-secondary-danger-hover: #9d4047;
  --color-font-button-secondary-danger-pressed: #7c313e;
  --color-font-button-secondary-danger-focus: #e05c5c;
  --color-font-button-secondary-danger-disabled: #5c3e3e;

  // tertiary
  // tertiary basic
  --color-font-button-tertiary: #fff;
  --color-font-button-tertiary-hover: #b6b6bd;
  --color-font-button-tertiary-pressed: #82838f;
  --color-font-button-tertiary-focus: #fff;
  --color-font-button-tertiary-disabled: #54556f;

  // tertiary danger
  --color-font-button-tertiary-danger: #e05c5c;
  --color-font-button-tertiary-danger-hover: #9d4047;
  --color-font-button-tertiary-danger-pressed: #7c313e;
  --color-font-button-tertiary-danger-focus: #e05c5c;
  --color-font-button-tertiary-danger-disabled: #5c3e3e;
}
