@mixin colors {
  --surface-background: #1b1d3a;
  --surface-island: #292b4b;
  --surface-on-island: #35375a;
  --surface-on-island-2: #484a73;
  --surface-popup-bg-blur: #161728e0;
  --text-text: #fff;
  --text-text-92: #ffffffeb;
  --text-text-88: #ffffffe0;
  --text-text-80: #fffc;
  --text-text-72: #ffffffb8;
  --text-text-64: #ffffffa3;
  --text-text-56: #ffffff8f;
  --text-text-48: #ffffff7a;
  --text-text-40: #fff6;
  --text-text-32: #ffffff52;
  --text-text-24: #ffffff3d;
  --text-text-16: #ffffff29;
  --text-text-8: #ffffff14;
  --text-text-4: #ffffff0a;
  --pink-pink-400: #e02379;
  --button-button-secondary-base: #6b6d95;
  --button-button-primary-text-default: #fff;
  --button-button-secondary-text: #fff;
  --danger: #e05c5c;
  --pink-pink-0: #0a0103;
  --pink-pink-100: #500025;
  --pink-pink-200: #7b003d;
  --pink-pink-300: #a90057;
  --pink-pink-500: #f7468d;
  --pink-pink-600: #ff80aa;
  --pink-pink-700: #ffb2c8;
  --accent: #e02379;
  --warning: #e78a56;
  --gray-gray-0: #020203;
  --gray-gray-100: #23232d;
  --gray-gray-200: #3a3a4e;
  --gray-gray-300: #525370;
  --gray-gray-400: #6b6d95;
  --gray-gray-500: #8889ab;
  --gray-gray-600: #a6a7c0;
  --gray-gray-700: #c5c5d6;
  --button-button-primary-text-hover: #fffc;
  --button-button-primary-text-pressed: #fff6;
  --button-button-secondary-base-hover: #525370;
  --button-button-secondary-base-pressed: #3a3a4e;
  --pink-pink-800: #fec9d7;
  --pink-pink-900: #fcdee6;
  --pink-pink-1000: #fbe9f1;
  --gray-gray-800: #d5d5e1;
  --gray-gray-900: #e1e1eb;
  --gray-gray-1000: #ededf5;
  --cool: #81e95d;
}
