@use 'reset';
@use 'animations';
@use 'colors/day';
@use 'colors/night';
@use 'dimensions';
@use 'scrollbars';

@import 'overlayscrollbars/overlayscrollbars.css';

:root {
  @include animations.tokens;
  @include dimensions.tokens;
}

body {
  @include day.colors;

  &.m-dark-theme {
    @include night.colors;
  }

  --accent: var(--pink-pink-400);
  --accent-hover: var(--pink-pink-300);
  --accent-pressed: var(--pink-pink-200);

  background-color: var(--surface-background);
  color: var(--text-text);

  @include scrollbars.scrollbars($bg: var(--surface-background), $thumb: var(--text-text-32));
  @include scrollbars.overlayscrollbars();
}

* {
  // transition: color var(--animation-time-short), background-color var(--animation-time-short);
}
