.style-preview-text-before {
  display: none;
}

.style-preview-text-after {
  display: none;
}

.h1-preview .style-preview-text {
  color: var(--maincolor);
  font-family: var(--headfont);
  font-size: 42px;
}

.h1-1 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.h1-1 .style-preview-text-decoration {
}

.h1-1 .style-preview-text {
  font-weight: 700;
  letter-spacing: 0.125em;
  text-transform: uppercase;
}

.h1-3 .style-preview {
}

.h1-3 .style-preview-text-decoration {
  padding-left: 10px;
  border-left: 2px solid var(--textcolor);
  margin-left: -10px;
}

.h1-3 .style-preview-text {
}

.h1-4 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.h1-4 .style-preview-text-decoration {
  padding: 4px 24px;
  background-color: var(--maincolor);
  font-weight: 600;
}

.h1-4 .style-preview-text {
  color: var(--color-font-main);
}

.h1-5 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.h1-5 .style-preview-text-decoration {
}

.h1-5 .style-preview-text {
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.h1-6 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.h1-6 .style-preview-text-decoration {
  display: inline;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--maincolor);
}

.h1-6 .style-preview-text {
  font-weight: 600;
  letter-spacing: 0.125em;
  text-transform: uppercase;
}

.h1-7 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.h1-7 .style-preview-text-decoration {
}

.h1-7 .style-preview-text {
}

.h1-9 .style-preview {
}

.h1-9 .style-preview-text-decoration {
  display: inline-block;
  padding: 12px 24px;
  margin-left: -24px;
  background-color: var(--subsubcolor);
}

.h1-9 .style-preview-text {
  color: var(--textcolor);
}

.h1-10-container {
  display: none;
}

.h1-11 .style-preview-text {
  text-decoration: underline;
}

.h1-12 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.h1-12 .style-preview-text-decoration {
}

.h1-12 .style-preview-text {
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.h1-13 .style-preview-text {
  color: var(--color-font-main);
  -webkit-text-fill-color: var(--color-font-main);
  -webkit-text-stroke: 3px var(--maincolor);
  text-transform: uppercase;
}

.h1-14 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.h1-14 .style-preview-text-decoration {
  padding: 12px 30px;
  background-color: var(--maincolor);
  font-weight: 600;
}

.h1-14 .style-preview-text {
  color: var(--color-font-main);
}

.h1-15 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.h1-15 .style-preview-text {
  font-weight: 700;
  letter-spacing: 0.125em;
  text-transform: uppercase;
}

.h1-16-container {
  display: none;
}

.h1-17-container {
  display: none;
}

.h2-preview .style-preview-text {
  color: var(--textcolor);
  font-family: var(--headfont);
  font-size: 28px;
}

.h2-1 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.h2-1 .style-preview-text-decoration {
}

.h2-1 .style-preview-text {
  font-weight: 700;
  letter-spacing: 0.125em;
  text-transform: uppercase;
}

.h2-3 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.h2-3 .style-preview-text-decoration {
}

.h2-3 .style-preview-text {
  letter-spacing: 0.14em;
  text-transform: uppercase;
}

.h2-4 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.h2-4 .style-preview-text-decoration {
}

.h2-4 .style-preview-text {
  color: var(--subcolor);
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.h2-5 .style-preview-text {
  color: var(--subcolor);
}

.h2-6-container,
.h2-7-container,
.h2-8-container,
.h2-9-container,
.h2-10-container {
  display: none;
}

.h2-11 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.h2-11 .style-preview-text {
  font-weight: 700;
  letter-spacing: 0.125em;
  text-transform: uppercase;
}

.h2-13 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.h2-13 .style-preview-text {
  letter-spacing: 0.14em;
  text-transform: uppercase;
}

.h2-14 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.h2-14 .style-preview-text {
  color: var(--subcolor);
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.h2-15 .style-preview-text {
  color: var(--subcolor);
}

.h2-16-container,
.h2-17-container {
  display: none;
}

.main-3-container,
.main-4-container,
.main-5-container,
.main-6-container,
.main-7-container,
.main-8-container,
.main-9-container,
.main-10-container,
.main-11-container,
.main-12-container,
.main-13-container,
.main-14-container,
.main-15-container,
.main-16-container,
.main-17-container {
  display: none;
}

.main-preview .style-preview-text {
  color: var(--textcolor);
  font-family: var(--textfont);
  font-size: 18px;
  line-height: 30px;
}

.main-2 .style-preview-text {
  display: block;
  text-align: justify;
}

.lead-10-container,
.lead-11-container,
.lead-12-container,
.lead-13-container,
.lead-14-container,
.lead-15-container,
.lead-16-container,
.lead-17-container {
  display: none;
}

.lead-preview .style-preview-text {
  color: var(--textcolor);
  font-family: var(--textfont);
  font-size: 18px;
  line-height: 24px;
}

.lead-1 .style-preview-text-decoration {
  position: relative;
}

.lead-1 .style-preview-text-decoration::before {
  position: absolute;
  top: 11%;
  left: -16px;
  width: 6px;
  height: 80%;
  background-color: var(--maincolor);
  content: '';
}

.lead-1 .style-preview-text {
  color: var(--maincolor);
}

.lead-2 .style-preview-text-decoration {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  border-top: 1px solid var(--maincolor);
  border-bottom: 1px solid var(--maincolor);
  border-left: 4px solid var(--maincolor);
  margin-left: -16px;
}

.lead-2 .style-preview-text {
  color: var(--textcolor);
}

.lead-3 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.lead-3 .style-preview-text {
  display: block;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
}

.lead-3 .style-preview-text {
  color: var(--maincolor);
}

.lead-4 .style-preview-text-decoration {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  border-top: 1px solid var(--maincolor);
  border-bottom: 2px solid var(--maincolor);
  margin-left: -16px;
}

.lead-4 .style-preview-text {
  color: var(--textcolor);
}

.lead-5 .style-preview-text-decoration {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  border: 1px solid var(--maincolor);
  margin-left: -16px;
  background-color: var(--subsubcolor);
}

.lead-5 .style-preview-text {
  color: var(--textcolor);
}

.lead-6 .style-preview-text-decoration {
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  margin-left: -16px;
  background-color: var(--subsubcolor);
}

.lead-6 .style-preview-text-decoration::before {
  position: absolute;
  top: 4px;
  bottom: -4px;
  left: -3px;
  width: 3px;
  background-color: var(--maincolor);
  content: ' ';
}

.lead-6 .style-preview-text-decoration::after {
  position: absolute;
  bottom: -4px;
  left: -3px;
  width: 100%;
  height: 3px;
  background-color: var(--maincolor);
  content: ' ';
}

.lead-6 .style-preview-text {
  color: var(--textcolor);
}

.lead-7 .style-preview-text-decoration {
  padding-top: 8px;
  padding-right: 10px;
  padding-bottom: 8px;
  padding-left: 16px;
  border-left: 3px solid var(--maincolor);
  margin-left: -16px;
  background-color: var(--subsubcolor);
  text-align: right;
}

.lead-7 .style-preview-text {
  color: var(--textcolor);
}

.lead-8 .style-preview-text {
  padding-bottom: 3px;
  background-image: linear-gradient(transparent 1.1ex, var(--subsubcolor) 0%);
  background-position: 0 0;
  background-repeat: repeat-y;
  color: var(--textcolor);
}

.lead-9 .style-preview-text-decoration {
  padding-bottom: 12px;
  border-bottom: 3px dotted var(--maincolor);
}

.lead-9 .style-preview-text {
  color: var(--maincolor);
}

.quote-preview .style-preview-text {
  color: var(--textcolor);
  font-family: var(--textfont);
  font-size: 20px;
}

.quote-preview .style-preview-text-before,
.quote-preview .style-preview-text-after {
  display: inline;
  color: var(--textcolor);
  font-family: var(--textfont);
  font-size: 20px;
}

.quote-1 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.quote-1 .style-preview-text-decoration {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 4px 8px;
  border-top: 1px solid var(--maincolor);
  border-bottom: 1px solid var(--maincolor);
}

.quote-2 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.quote-2 .style-preview-text-decoration {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 12px 4px 14px;
  background-color: var(--subsubcolor);
  border-radius: 3px;
}

.quote-2 .style-preview-text-before,
.quote-2 .style-preview-text-after {
  color: var(--color-font-main);
}

.quote-3 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.quote-3 .style-preview-text-decoration {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 4px 14px;
}

.quote-3 .style-preview-text {
  color: var(--maincolor);
}

.quote-3 .style-preview-text-before,
.quote-3 .style-preview-text-after {
  color: var(--maincolor);
}

.quote-4 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.quote-4 .style-preview-text-decoration {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 4px 14px;
}

.quote-4 .style-preview-text,
.quote-4 .style-preview-text-before,
.quote-4 .style-preview-text-after {
  font-style: italic;
}

.quote-4 .style-preview-text-before,
.quote-4 .style-preview-text-content,
.quote-4 .style-preview-text-after {
  background-image: linear-gradient(transparent 1.55ex, var(--subsubcolor) 0%);
  background-position: 0 0;
  background-repeat: repeat-y;
}

.quote-5 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.quote-5 .style-preview-text-decoration {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 40px 14px;
  border: 2px dotted var(--subsubcolor);
  border-radius: 3px;
}

.quote-5 .style-preview-text,
.quote-5 .style-preview-text-before,
.quote-5 .style-preview-text-after {
  color: var(--maincolor);
}

.quote-6 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.quote-6 .style-preview-text-decoration {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 12px 4px;
  border-bottom: 4px solid var(--maincolor);
  background-color: var(--subsubcolor);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.quote-6 .style-preview-text,
.quote-6 .style-preview-text-before,
.quote-6 .style-preview-text-after {
  color: var(--maincolor);
}

.quote-7 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.quote-7 .style-preview-text-decoration {
  position: relative;
  z-index: 0;
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 20px;
  margin-right: 10px;
  background-color: var(--subsubcolor);
  border-radius: 3px;
}

.quote-7 .style-preview-text-content,
.quote-7 .style-preview-text-before,
.quote-7 .style-preview-text-after {
  position: relative;
  z-index: 2;
  color: var(--maincolor);
}

.quote-7 .style-preview-text-after {
  display: none;
}

.quote-7 .style-preview-text-before {
  position: absolute;
  z-index: 1;
  top: -35px;
  left: 5px;
  color: var(--color-font-main);
  font-size: 64px;
  font-weight: bold;
}

.quote-8-container,
.quote-9-container,
.quote-10-container {
  display: none;
}

.quote-11 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.quote-11 .style-preview-text-decoration {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 4px 14px;
  border-top: 1px solid var(--maincolor);
  border-bottom: 1px solid var(--maincolor);
}

.quote-11 .style-preview-text-before {
  position: relative;
  color: transparent;
}

.quote-11 .style-preview-text-before::before {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--textcolor);
  content: '“';
}

.quote-11 .style-preview-text-after {
  position: relative;
  color: transparent;
}

.quote-11 .style-preview-text-after::before {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--textcolor);
  content: '”';
}

.quote-12 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.quote-12 .style-preview-text-decoration {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 12px 4px 14px;
  background-color: var(--subsubcolor);
  border-radius: 3px;
}

.quote-12 .style-preview-text-before {
  position: relative;
  color: transparent;
}

.quote-12 .style-preview-text-before::before {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--textcolor);
  content: '“';
}

.quote-12 .style-preview-text-after {
  position: relative;
  color: transparent;
}

.quote-12 .style-preview-text-after::before {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--textcolor);
  content: '”';
}

.quote-13 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.quote-13 .style-preview-text-decoration {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 4px 14px;
}

.quote-13 .style-preview-text {
  color: var(--maincolor);
}

.quote-13 .style-preview-text-before,
.quote-13 .style-preview-text-after {
  color: var(--maincolor);
}

.quote-13 .style-preview-text-before {
  position: relative;
  color: transparent;
}

.quote-13 .style-preview-text-before::before {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--maincolor);
  content: '“';
}

.quote-13 .style-preview-text-after {
  position: relative;
  color: transparent;
}

.quote-13 .style-preview-text-after::before {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--maincolor);
  content: '”';
}

.quote-14 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.quote-14 .style-preview-text-decoration {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 4px 14px;
}

.quote-14 .style-preview-text,
.quote-14 .style-preview-text-before,
.quote-14 .style-preview-text-after {
  font-style: italic;
}

.quote-14 .style-preview-text-before,
.quote-14 .style-preview-text-content,
.quote-14 .style-preview-text-after {
  background-image: linear-gradient(transparent 1.55ex, var(--subsubcolor) 0%);
  background-position: 0 0;
  background-repeat: repeat-y;
}

.quote-14 .style-preview-text-before {
  position: relative;
  color: transparent;
}

.quote-14 .style-preview-text-before::before {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--textcolor);
  content: '“';
}

.quote-14 .style-preview-text-after {
  position: relative;
  color: transparent;
}

.quote-14 .style-preview-text-after::before {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--textcolor);
  content: '”';
}

.quote-15 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.quote-15 .style-preview-text-decoration {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 40px 14px;
  border: 2px dotted var(--subsubcolor);
  border-radius: 3px;
}

.quote-15 .style-preview-text,
.quote-15 .style-preview-text-before,
.quote-15 .style-preview-text-after {
  color: var(--maincolor);
}

.quote-15 .style-preview-text-before {
  position: relative;
  color: transparent;
}

.quote-15 .style-preview-text-before::before {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--maincolor);
  content: '“';
}

.quote-15 .style-preview-text-after {
  position: relative;
  color: transparent;
}

.quote-15 .style-preview-text-after::before {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--maincolor);
  content: '”';
}

.quote-16 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.quote-16 .style-preview-text-decoration {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 12px 4px;
  border-bottom: 4px solid var(--maincolor);
  background-color: var(--subsubcolor);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.quote-16 .style-preview-text,
.quote-16 .style-preview-text-before,
.quote-16 .style-preview-text-after {
  color: var(--maincolor);
}

.quote-16 .style-preview-text-before {
  position: relative;
  color: transparent;
}

.quote-16 .style-preview-text-before::before {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--maincolor);
  content: '“';
}

.quote-16 .style-preview-text-after {
  position: relative;
  color: transparent;
}

.quote-16 .style-preview-text-after::before {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--maincolor);
  content: '”';
}

.quote-17 .style-preview {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.quote-17 .style-preview-text-decoration {
  position: relative;
  z-index: 0;
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 20px;
  margin-right: 10px;
  background-color: var(--subsubcolor);
  border-radius: 3px;
}

.quote-17 .style-preview-text-content,
.quote-17 .style-preview-text-before,
.quote-17 .style-preview-text-after {
  position: relative;
  z-index: 2;
  color: var(--maincolor);
}

.quote-17 .style-preview-text-after {
  display: none;
}

.quote-17 .style-preview-text-before {
  position: absolute;
  z-index: 1;
  top: -17px;
  left: 5px;
  color: var(--color-font-main);
  font-size: 64px;
  font-weight: bold;
}

.quote-17 .style-preview-text-before {
  color: transparent;
}

.quote-17 .style-preview-text-before::before {
  position: absolute;
  width: 100%;
  height: 100%;
  color: var(--color-font-main);
  content: '“';
}

.prim-4-container,
.prim-5-container,
.prim-6-container,
.prim-7-container,
.prim-8-container,
.prim-9-container,
.prim-10-container,
.prim-11-container,
.prim-12-container,
.prim-13-container,
.prim-14-container,
.prim-15-container,
.prim-16-container,
.prim-17-container {
  display: none;
}

.prim-preview .style-preview-text {
  color: var(--textcolor);
  font-family: var(--textfont);
  font-size: 18px;
  line-height: 24px;
}

.prim-1 .style-preview-text {
  color: var(--maincolor);
}

.prim-2 .style-preview-text-decoration {
  position: relative;
  margin-left: 1.1em;
}

.prim-2 .style-preview-text-decoration::before {
  position: absolute;
  top: 14%;
  left: -1.1em;
  width: 3px;
  height: 85%;
  background-color: var(--maincolor);
  content: '';
}

.prim-3 .style-preview-text-decoration {
  padding-top: 4px;
  border-top: 2px solid var(--maincolor);
}

.prim-3 .style-preview-text {
  font-style: italic;
  font-weight: 600;
}
