@import 'mixins';

@mixin element-color($color) {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M8 6.585L14.2929 0.292893C14.6534 -0.0675905 15.2206 -0.0953204 15.6129 0.209704L15.7071 0.292893C16.0976 0.683418 16.0976 1.31658 15.7071 1.70711L9.415 8L15.7071 14.2929C16.0676 14.6534 16.0953 15.2206 15.7903 15.6129L15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8 9.415L6.585 8L8 6.585Z" fill="#{$color}"/></svg>');
}

.onboarding-class {
  --color: var(--color-font-main, black);
  --background-color: transparent;
  --button-border-radius: 0;
  padding: 24px;
  border-radius: var(--border-radius-m);
  background-color: var(--color-bg-panel);

  .shepherd-header {
    padding: 0 !important;
    background: none !important;
  }

  .shepherd-arrow::before {
    background-color: var(--color-bg-panel) !important;
  }

  .shepherd-title {
    @include text;

    color: var(--color-font-main);
    font-size: var(--font-size);
    font-weight: var(--font-weight-bold);
  }

  .shepherd-cancel-icon {
    span {
      color: var(--color-font-main);
      font-weight: var(--font-weight);
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .shepherd-text {
    padding: 16px 0 24px;
    color: var(--color-font-main);
  }

  .shepherd-footer {
    padding: 0;
  }

  .shepherd-button {
    &.onboarding-button {
      display: inline-flex;
      width: fit-content;
      height: 48px;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      border-radius: var(--button-border-radius);
      background: var(--background-color);
      color: var(--color);
      cursor: pointer;
      font-weight: var(--font-weight-semibold);
      gap: 8px;
      user-select: none;

      &.primary {
        --color: var(--color-font-button-primary);
        --background-color: var(--color-bg-button-primary);
        --button-border-radius: var(--border-radius);

        &:hover {
          --color: var(--color-font-button-primary-hover);
          --background-color: var(--color-bg-button-primary-hover);
        }
      }

      &.secondary {
        --color: var(--color-font-button-secondary);
        --background-color: var(--color-bg-button-secondary);
        --button-border-radius: var(--border-radius);

        // &.back {
        //   position: relative;
        //   width: 50px;

        //   svg {
        //     position: absolute;
        //     left: 30%;

        //     path {
        //       fill: var(--color-font-main);
        //     }
        //   }

        // //   @include element-color(black);

        // //   width: 50px;
        // //   // background-image: url('/assets/icons/back.svg');
        // //   background-position: center;
        // //   background-position-x: 30%;
        // //   background-position-y: center;
        // //   background-repeat: no-repeat;
        // //   background-size: 20px;
        // }

        &:hover {
          --color: var(--color-font-button-secondary-hover);
          --background-color: var(--color-bg-button-secondary-hover);
        }
      }
    }
  }
}
