@mixin fonts {
  --font: 'Roboto';
  --font-mono: 'Roboto Mono';

  ///
  --font-size: 16px;
  --font-size-xxxs: 9px;
  --font-size-xxs: 10px;
  --font-size-xxs-plus: 11px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 18px;
  --font-size-l: 20px;
  --font-size-l-plus: 22px;
  --font-size-xl: 24px;
  --font-size-xxl: 28px;
  --font-size-xxxl: 32px;
  --font-size-xxxxl: 58px;

  // weights
  --font-weight: 400;
  --font-weight-em: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-bolder: 800;
}

@mixin margins {
  --padding: 16px;
  --padding-card: 24px;
  --margin-panel: 20px;
}

@mixin borders {
  --border-radius: 10px;
  --border-radius-xs: 4px;
  --border-radius-s: 7px;
  --border-radius-m: 10px;
  --border-radius-l: 16px;
  --border-radius-xxxl: 100px;
}

@mixin animations {
  --animation-time-short: 0.2s;
}

@mixin opacity {
  --opacity-disabled: 0.5;
}

@mixin vars {
  --app-bar-height: 64px;
  --nav-tabs-height: 40px;
  --nav-tabs-pricing-height: 40px;
  --side-padding: 40px;
  --font-size-basic: 16px;
}
