@use 'theming/base' as base;
@use 'theming/inputs' as inputs;
@use 'theming/buttons' as buttons;
@use 'theming/app-bar' as appBar;
@use 'theming/editor' as editor;
@use 'theming/pricing' as pricing;

@use 'theming/pages/markup' as markupPage;
@use 'theming/pages/build' as buildPage;
@use 'theming/pages/margins' as marginsPage;
@use 'theming/pages/running-titles' as runningTitlesPage;
@use 'theming/pages/preview' as previewPage;
@use 'theming/pages/select-templates' as selectTemplatesPage;

@use 'mixins' as mixins;
@use 'tokens' as tokens;

:root {
  @include tokens.fonts;
  @include tokens.margins;
  @include tokens.borders;
  @include tokens.animations;
  @include tokens.opacity;
  @include tokens.vars;
}

body {
  @include base.common;

  @include base.light;

  // components
  @include inputs.light;
  @include buttons.light;
  @include appBar.light;
  @include editor.light;
  @include pricing.light;

  // pages
  @include markupPage.light;
  @include buildPage.light;
  @include marginsPage.light;
  @include runningTitlesPage.light;
  @include previewPage.light;
  @include selectTemplatesPage.light;
}

body.m-dark-theme {
  @include base.dark;

  // components
  @include inputs.dark;
  @include buttons.dark;
  @include appBar.dark;
  @include editor.dark;
  @include pricing.dark;

  // pages
  @include markupPage.dark;
  @include buildPage.dark;
  @include marginsPage.dark;
  @include runningTitlesPage.dark;
  @include previewPage.dark;
  @include selectTemplatesPage.dark;
}
