/*
Usage:

<span class="tooltip-host">
    <img src="/assets/img/info.svg" class="info" />
    <span class="tooltip" i18n="@@editor.image.allow_crop_hint">editor.image.allow_crop_hint</span>
</span>

On tooltip-host hover, tooltip will be showed.
*/

@import 'mixins';

.tooltip-host {
  position: relative;
}

.tooltip {
  @include elevated;

  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  min-width: 350px;
  padding: 4px;
  margin-left: -170px;
  text-align: justify;
  visibility: hidden;
}

.tooltip-host:hover .tooltip {
  visibility: visible;
}
