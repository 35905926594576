@use 'sass:color';

@mixin light {
  --color-border-preview: #5d6476;
  --color-book-viewer-dot: var(--color-bg-panel);
  --color-book-viewer-dot-selected: var(--color-bg-popup);
  --color-bg-maket-type-book: #fff;

  // images
  --image-section-title-toggle: url('/assets/img/select-down-dark.svg');
  --image-section-title-toggle-expanded: url('/assets/img/select-up-dark.svg');

  // show editor button
  --color-bg-build-show-editor: #dcdce0;
  --color-bg-build-show-editor-hover: #{color.change(#dcdce0, $alpha: 0.5)};

  // home page promo-menu-buttons
  --color-promo-menu-buttons-gradient: radial-gradient(58.54% 130.38% at 50% 0%, #a348ff 18.9%, #e88cff 100%);
}

@mixin dark {
  --color-border-preview: var(--color-bg-main);
  --color-book-viewer-dot: var(--color-bg-panel);
  --color-book-viewer-dot-selected: var(--color-bg-popup);
  --color-bg-maket-type-book: #ececec;

  // images
  --image-section-title-toggle: url('/assets/img/select-down.svg');
  --image-section-title-toggle-expanded: url('/assets/img/select-up.svg');

  // show editor button
  --color-bg-build-show-editor: #35375a;
  --color-bg-build-show-editor-hover: #{color.change(#35375a, $alpha: 0.5)};

  // home page promo-menu-buttons
  --color-promo-menu-buttons-gradient: radial-gradient(
    61.44% 145.66% at 50% -13.19%,
    #b937c5 7.53%,
    #8931ac 35.39%,
    #2d2467 90.36%
  );
}
