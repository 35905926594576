@mixin tokens {
  // TODO maybe we should split these into different files
  // like dimensions/island.scss, dimensions/button.scss, etc.
  --island-radius-16: 16px;
  --island-radius-42: 42px;
  --island-padding-0: 0px;
  --island-padding-8: 8px;
  --island-padding-16: 16px;
  --island-padding-24: 24px;

  // font-sizes
  --font-size-8: 8px;
  --font-size-10: 10px;
  --font-size-11: 11px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-28: 28px;
  --font-size-32: 32px;
  --font-size-36: 36px;
}
