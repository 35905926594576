@mixin light {
  --color-font-style-change-button: var(--color-font-main);
  --color-bg-style-change-button: #f4f4f4;
  --color-bg-style-change-button-active: #fff;
}

@mixin dark {
  --color-font-style-change-button: var(--color-font-main);
  --color-bg-style-change-button: #2b2d4e;
  --color-bg-style-change-button-active: #1e203c;
}
